@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.container {
  display: inline-block;
}

.tooltipOverride {
  background-color: $eycrowd-color-white !important;
  border: $eycrowd-border-active !important;
  border-radius: $eycrowd-radius-sm !important;
  width: rem(290px);
  opacity: 1 !important;
  padding: 10px !important;
  overflow-wrap: break-word !important;
}

.label {
  color: $eycrowd-color-gray;
  font-size: $eycrowd-font-size-xs;
  font-weight: 500;
}
