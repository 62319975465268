@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.poweredByGoogleLogo {
  height: rem(18px);
  width: rem(144px);
  position: absolute;
  right: 1%;
  bottom: 0;
}

.field {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: $eycrowd-font-size-stack;
}

.label {
  font-size: $eycrowd-font-size-sm;
  line-height: $eycrowd-font-line-height-xxs;
  // color: $eycrowd-color-light-gray-01;
  margin-bottom: rem(4px);
  // font-weight: 500;
  display: flex;
}

.iconSingleValue {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input {
  height: rem(44px);
  width: 100%;
  padding: rem(10px) 0 rem(10px) rem(10px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: $eycrowd-radius-sm;
  outline: none;
  font-family: $eycrowd-font-stack;
  color: $eycrowd-color-black-02;
  font-size: $eycrowd-font-size-stack;

  &::placeholder {
    color: $eycrowd-color-light-gray-01;
  }

  &:focus {
    border: 2px solid $eycrowd-color-red;
  }
}

.inputSaved {
  background-color: lightgray;
}

.inputFieldLarge {
  font-size: $eycrowd-font-size-1-37x;
  border: none;
  padding: rem(10px) 0;
  margin-bottom: 0;
  font-weight: 600;

  &::placeholder {
    font-size: $eycrowd-font-size-1-37x;
  }

  &:focus {
    border: none;
  }
}

.required {
  border: $eycrowd-color-red;
}

.optional {
  position: absolute;
  bottom: rem(25px);
  right: rem(15px);
  font-size: $eycrowd-font-size-xs;
  font-weight: 600;
  opacity: 0.1;
  text-transform: uppercase;
  color: $eycrowd-color-blue-dark;
}

.eyeButton {
  border: none;
  background: transparent;
  opacity: 0.2;
  outline: transparent;
  position: absolute;
  top: rem(30px);
  right: rem(15px);
}

.multiSelectInput {
  & > div:first-of-type {
    font-size: $eycrowd-font-size-stack;
    font-family: $eycrowd-font-stack;
    min-height: rem(44px);
    border-radius: $eycrowd-radius-sm;
    box-shadow: none;
  }
}

.selectInput {
  height: rem(44px);
  width: 100%;
  padding: rem(10px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: $eycrowd-radius-sm;
  color: $eycrowd-color-light-gray-01;
  outline: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  position: relative;
  font-size: 1rem;
  font-family: $eycrowd-font-stack;

  @include media-max($eycrowd-screen-mobile-landscape) {
    width: 100%;
    min-width: 280px;
  }
}

.multiselectTextInputFieldWrapper {
  display: flex;
  justify-content: space-between;
  height: rem(44px);
  width: 100%;
  padding: rem(10px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: $eycrowd-radius-sm;
  color: $eycrowd-color-light-gray-01;
  outline: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  position: relative;
  font-size: 1rem;
  font-family: $eycrowd-font-stack;

  @include media-max($eycrowd-screen-mobile-landscape) {
    width: 100%;
    min-width: 280px;
  }
}

.multiSelectTextInputField {
  border: none;
  outline: none;
  width: 100%;
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.multiSelectInputFieldWrapper {
  display: flex;
  flex-direction: column;
  background-color: $eycrowd-color-light-gray-03;
  border-radius: $eycrowd-radius-sm;
  overflow-y: scroll;
  height: rem(270px);
  padding: rem(15px);
  &::-webkit-scrollbar {
    width: rem(4px);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.multiSelectInputField {
  font-family: $eycrowd-font-stack;
  display: flex;
  line-height: rem(27px);
  padding: rem(1px) rem(1px) rem(1px) rem(1px);
  word-break: break-word;
}

.multiselectFieldValueIcon {
  width: rem(22px);
  height: rem(22px);
  &:hover {
    cursor: pointer;
  }
}

.textarea {
  height: rem(60px);
  padding: rem(5px);
}

.textareaLarge {
  height: rem(120px);
  padding: rem(5px);
}

.currentlySelectedValuesLabel {
  font-style: italic;
  font-size: $eycrowd-font-size-xs;
  margin-right: rem(8px);
  padding-left: rem(1px);
  padding-top: rem(1px);
  color: $eycrowd-color-blue-dark;
}

.currentlySelectedValuesWrapper {
  background-color: $eycrowd-color-light-gray-03;
  border-radius: $eycrowd-radius-md;
  padding: rem(8px);
  font-size: $eycrowd-font-size-sm;
  margin-top: rem(8px);
  font-weight: 500;
  color: $eycrowd-color-blue-02;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.currentlySelectedValuesWrapperButtonMargin {
  min-height: rem(42px);
}

.checkboxButtonWrapper {
  display: flex;
  margin-right: rem(-8px);
  .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: rem(110px);
    width: 100%;
    font-size: $eycrowd-font-size-sm;
    padding: rem(15px) 0 rem(20px);
    position: relative;
    margin-right: rem(8px);
  }

  .input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: $eycrowd-z-index-input;
    cursor: pointer;
  }

  .customCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: 1px solid $eycrowd-color-light-gray-01;
    border-radius: $eycrowd-radius-md;

    &:hover {
      border: $eycrowd-border-active;
    }
  }

  input:checked ~ .customCheckmark {
    border: $eycrowd-border-active;
  }
}

.rangeFieldInputWrapper {
  display: flex;
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: $eycrowd-radius-sm;
  padding: rem(3px) rem(6px);
  font-weight: 600;
  color: #5c5c5c;
  height: rem(30px);
}

.rangeFieldInput {
  min-width: rem(27px);
  border: none;
  text-align: center;
  font-weight: 600;
  color: #5c5c5c;
  max-width: 3rem;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:disabled {
    background-color: white;
  }
}

.inputText {
  color: #5c5c5c;
}

.rangeInputWrapperInner {
  display: flex;
  padding: 0 rem(4px);
}

.doubleRangeInputFieldWrapper {
  display: flex;
}

.rangeFieldHiddenSLabel:after {
  content: 's';
  visibility: hidden;
}

.rangeFieldMinMaxLabelsWrapper {
  display: flex;
  justify-content: space-between;
  font-size: $eycrowd-font-size-sm;
  color: #3b3b3b;
}

.fileInput {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.socialPlatformsItem {
  font-size: rem(14px);
  color: $eycrowd-color-blue-dark;
  margin-top: rem(5px);
}

.searchField {
  display: flex;
  width: rem(340px);
  height: rem(40px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: $eycrowd-radius-sm;
  overflow: hidden;
}

.searchInput {
  width: 100%;
  border: none;
  padding: 0 rem(16px);
  font-size: $eycrowd-font-size-sm;
  color: $eycrowd-color-black-02;

  &:focus {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    display: none;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  right: 50%;
  height: rem(150px);
  transform: translate(50%, -50%);
}

.searchIconWrapper {
  display: flex;
  align-items: center;
  padding: 0 rem(12px);
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.cursorDisabled {
  cursor: not-allowed !important;
}

.rangeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.descriptionWrapper {
  font-size: $eycrowd-font-size-xs;
  color: $eycrowd-color-gray;
  width: 70%;
}

.sliderLabelWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  strong {
    font-weight: 600;
  }
}

.requiredSign {
  color: $eycrowd-color-red;
}

.multiselectLabel {
  &:hover {
    color: $eycrowd-color-red;
    text-decoration: underline;
  }
}
