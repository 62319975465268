@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $eycrowd-radius-md;
  background-color: $eycrowd-color-black-02;
  opacity: 1;
  color: $eycrowd-color-white;
  border: none;
  font-size: $eycrowd-font-size-sm;
  font-weight: 500;
  border: 1px solid transparent;
  position: relative;

  img {
    position: absolute;
    top: 0.4rem;
    right: 0.25rem;
    width: 2rem;
    height: 2rem;
  }
}

.buttonReverse {
  background-color: $eycrowd-color-white;
  color: $eycrowd-color-black-02;
  border-color: $eycrowd-color-white-01;
}

.alt {
  background: $eycrowd-gradient-main;
  box-shadow: $eycrowd-shadow-dark-red;
  color: $eycrowd-color-white;
}

.green {
  background: $eycrowd-gradient-green;
}

.altReverse {
  border-color: $eycrowd-color-white;
  background-color: $eycrowd-color-white;
  box-shadow: $eycrowd-shadow-01;
  color: $eycrowd-color-red;
}

.disabled {
  background-color: rgba($eycrowd-color-light-gray, 0.1);
  color: rgba($eycrowd-color-black-02, 0.4);
  background-image: none;
  box-shadow: none;
  border-color: transparent;
}

.buttonLg {
  width: rem(335px);
  margin-top: rem(38px);
  height: rem(48px);

  @include media-max($eycrowd-screen-mobile-landscape) {
    width: 100%;
    min-width: rem(280px);
  }
}

.loadMoreButton {
  background: transparent;
  color: $eycrowd-color-red;
  font-size: $eycrowd-font-size-stack;
  font-weight: 600;
  padding: 10px;
  margin: auto;

  &:hover {
    text-decoration: underline;
  }
}

.loadMoreButtonGallery {
  width: 100%;
  height: rem(30px);
  border-radius: rem(7px);
  background: $eycrowd-color-light-gray-02;
  color: $eycrowd-color-black-02;
}

.loadMoreButtonGalleryIcon {
  margin-left: rem(8px);
}
