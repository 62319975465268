@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.bannerContainer {
  width: 100%;
  background-color: #fe5f55;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-left-radius: rem(20px);
  border-bottom-right-radius: rem(20px);
}

.title {
  color: #ffffff;
  font-family: Avenir;
  font-size: rem(16px);
  font-weight: 900;
  letter-spacing: rem(-0.21px);
  line-height: rem(22px);
}

.text {
  color: #ffffff;
  font-family: Avenir;
  font-size: rem(15px);
  font-weight: 300;
  letter-spacing: rem(-0.2px);
  line-height: rem(20px);
}

.textContainer {
  margin: rem(30px) 0;
}

.icon {
  color: white;
  margin-left: rem(24px);
  margin-right: rem(24px);
  size: 40px;
}
