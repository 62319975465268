@import '../../../styles/abstract/variables';
@import '../../../styles/abstract/mixins';

.container {
  // max-width: rem(1550px);
  padding: rem(20px) rem(24px) rem(65px) rem(275px);
}

.header {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  min-height: rem(72px);
  left: 0;
  right: 0;
  box-shadow: $eycrowd-shadow;
  padding: rem(26px) rem(56px);
  background: $eycrowd-color-white;
  z-index: 5;
}

.dropdownToggler {
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-word;
}

.headerCompanyName {
  font-size: $eycrowd-font-size-sm;
  color: $eycrowd-color-blue-dark;
  font-weight: 500;
}

.sideBar {
  background-color: $eycrowd-color-primary;
  height: 100%;
  width: rem(250px);
  padding: rem(10px);
  box-shadow: $eycrowd-side-shadow;
  z-index: 6;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  padding: rem(60px) 0;

  img {
    width: rem(155px);
    height: rem(21px);
  }
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: rem(20px);
  color: $eycrowd-color-text;
  transition: $eycrowd-transition;
  font-weight: 500;
}

.linkActive {
  background-color: $eycrowd-color-white;
  box-shadow: $eycrowd-box-shadow;
  border-radius: $eycrowd-radius-sm;
  font-weight: 600;
}

.mainHeadeWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainHeadeRightWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.title {
  font-size: $eycrowd-font-size-lg;
  color: $eycrowd-color-black-02;
  word-break: break-word;
  font-weight: 600;
}

.titleMain {
  line-height: $eycrowd-font-line-height-stack;
  color: $eycrowd-color-blue-dark;
  word-break: break-word;
}

.dashboardHeaderButton {
  padding: 0 rem(20px);
  width: rem(225px);
  height: rem(40px);
  margin-left: rem(34px);
  margin-top: rem(4px);
  margin-bottom: rem(4px);
  border-radius: $eycrowd-radius-sm !important;
}

.dashboardHeaderButtonLeft {
  justify-content: flex-start !important;
}

.createCampaignButtonIcon {
  margin-right: rem(18px);
}

.titleWrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.status {
  margin-left: rem(16px);
  white-space: nowrap;
}

.return {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.returnText {
  font-size: $eycrowd-font-size-sm;
  font-weight: 500;
  color: $eycrowd-color-blue-dark;
  margin-left: rem(6px);
}

.arrowIconDown {
  width: rem(10px);
  transform: rotate(90deg);
  margin-left: rem(15px);
}

.arrowIconUp {
  width: rem(10px);
  transform: rotate(-90deg);
  margin-left: rem(15px);
}

.dropdownListItem {
  position: relative;
}

.dropdownWrapper {
  position: absolute;
  top: 10px;
  left: -75px;
  border-radius: $eycrowd-radius-sm;
  width: rem(155px);
  background: $eycrowd-color-white;
  box-shadow: $eycrowd-box-shadow;
  overflow: hidden;
}

.dropdownButton {
  background: transparent;
  width: 100%;
  padding: rem(10px) 0;
  font-size: rem(14px);
  border: none;
  transition: background-color 0.4s, color 0.4s;
  font-weight: 500;

  &:hover {
    background-color: $eycrowd-color-red;
    color: $eycrowd-color-white;
  }
}

.verificationMessage {
  color: $eycrowd-color-red;
  font-weight: 700;
  font-size: rem(16px);
  margin-bottom: rem(16px);
  position: relative;
  top: rem(60px);
}

.iconWrapper {
  display: flex;
  margin-right: rem(16px);
}

.greenBtn {
  background-color: #268a45;
}

.tabsContainer {
  display: flex;
  height: rem(40px);
  align-items: center;
}

.tabsWrapper {
  display: flex;
  border-radius: $eycrowd-radius-sm;
  padding: rem(7px);
  box-shadow: $eycrowd-shadow;
}

.tab {
  cursor: pointer;
  transition: 0.2s ease all;
  position: relative;
  width: 110px;
  text-align: center;
  font-weight: 500;
  color: $eycrowd-color-gray-02;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    font-weight: 600;
    font-size: rem(17px);
    color: $eycrowd-color-black;
  }

  //   &::after {
  //     content: '';
  //     display: block;
  //     width: 30px;
  //     height: 4px;
  //     border-radius: 2px;
  //     background: #fd5f55;
  //     position: absolute;
  //     bottom: -7px;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     opacity: 0;
  //     transition: 0.4s;
  //   }
}

.activeTab {
  font-weight: 600;
  font-size: rem(17px);
  color: $eycrowd-color-black;

  &::after {
    opacity: 1;
  }
}

.separator {
  color: $eycrowd-color-gray-03;
  font-size: rem(17px);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dashboardHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboardTitle {
  font-size: $eycrowd-font-size-lg;
  color: $eycrowd-color-black-02;
  word-break: break-word;
  font-weight: 600;
}

.totalSummaryContainer {
  box-shadow: $eycrowd-box-shadow;
  border-radius: $eycrowd-radius-sm;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 30px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.labelAndValueWrapper {
  display: flex;
  flex-direction: column;
}

.summaryCardWrapper {
  display: flex;
  width: 250px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.verticalSeparator {
  border-right: 1px solid $eycrowd-color-gray-03;
  padding-top: 40px;
  padding-bottom: 40px;
}

.totalSummaryLabel {
  color: $eycrowd-color-gray-03;
  font-size: medium;
}

.totalSummaryValue {
  color: $eycrowd-color-black-02;
  font-weight: 500;
  font-size: larger;
  padding-top: 5px;
}

.totalSummaryImageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.image {
  max-width: 60px;
  max-height: 60px;
}

.spinner {
  height: rem(20px);
  width: rem(20px);
}

.spaceBetween {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
